<template>
  <div class="mini-upload">
    <van-loading type="spinner" vertical>处理中</van-loading>
  </div>
</template>

<script>
  import templateFileApis from '@/api/core/templateFile'
  import { generateRandomKey } from '@/utils/helper'
  import { createSimpleLaunch } from '@/model/launch'

  export default {
    name: 'MiniUpload',
    data() {
      return {}
    },
    created() {
      let { fileKey, fileName, objectFormat, fileSize } = this.$route.query
      this.fileName = decodeURIComponent(fileName)
      this.objectFormat = decodeURIComponent(objectFormat)
      this.fileKey = decodeURIComponent(fileKey)
      this.fileSize = fileSize
      this.getUploadRes()
    },
    methods: {
      async getUploadRes() {
        const model = {
          fileName: this.fileName,
          objectFormat: this.objectFormat,
          key: this.fileKey,
          fileSize: this.fileSize,
          randomId: generateRandomKey(),
        }
        const res = await templateFileApis.uploadFileToApplet(model)
        let fileInfo = await this.getUploadFile(res)
        if (fileInfo && !fileInfo.imgList && fileInfo.fileKey) {
          const data = await this.getImageList(fileInfo.fileKey)
          fileInfo = {
            ...fileInfo,
            ...data,
          }
        }

        await createSimpleLaunch(fileInfo)
      },
      async getPdfInfo(model) {
        const res = await templateFileApis.getPdfInfo(model)
        return res
      },

      async getImageList(key) {
        const model = {
          fileKey: key,
        }
        const res = await templateFileApis.getImageList(model)
        return {
          fileUrl: res && res[0],
          imgList: res,
        }
      },

      getUploadFile(res) {
        return new Promise((resolve, reject) => {
          let loading = false
          let timer
          try {
            if (res) {
              if (res.taskId) {
                timer = setInterval(async () => {
                  try {
                    if (loading) return
                    const model = res
                    loading = true
                    const imgList = await this.getPdfInfo(model)
                    loading = false
                    if (imgList) {
                      clearInterval(timer)
                      timer = null
                      resolve({
                        ...res,
                        imgList,
                        fileUrl: imgList && imgList[0],
                      })
                    }
                  } catch (error) {
                    loading = false
                    clearInterval(timer)
                    timer = null
                    reject(error)
                  }
                }, 2000)
              } else {
                resolve(res)
              }
            }
          } catch (error) {
            loading = false
            reject(error)
            timer = null
            clearInterval(timer)
            console.error('上传文件错误', error)
          } finally {
            loading = false
          }
        })
      },
    },
  }
</script>

<style lang="less" scoped>
  .mini-upload {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
